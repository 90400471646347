<template>
  <main>
    <form>
    <div class="status">
      <div class="participation">
        <!-- <h1>Dear Mr/ Mrs {{submission.first_name}} {{submission.last_name}}</h1>
        <h2>Your participation at the operation {{ title }}</h2> -->
        <h1>Merci pour votre participation a la prmotion Automower® Cashback</h1>
        <h3>
        Status de la participation :
        <span v-if="status=='valid'"   class="blue"><i class="far fa-check-circle ico"></i> Validé</span>
        <span v-if="status=='invalid'" class="orange"><i class="far fa-times-circle ico"></i> Non conforme</span>
        <span v-if="status=='pending'" class="gray"><i class="far fa-clock ico"></i> En attente</span>
        <span v-if="status=='refund'"  class="blue"><i class="far fa-clock ico"></i> Remboursement effectué</span>
        <span v-if="status=='missing'" class="orange"><i class="far fa-clock ico"></i> à corriger</span>
        </h3>
      </div>

     <p v-if="status=='invalid' || status=='missing'">Motif de non conformité  : {{ motif_nc }}</p>
     <p v-if="status=='invalid' || status=='missing'">Commentaire: {{ validation_comment }}</p>
     <br />

     <p v-if="status=='valid'">Votre participation a été validée par nos équipes. Nous allons effectuer votre remboursement dans un délai de 10 jours ouvrés.</p>
     <p v-else-if="status=='missing'">Votre participation n'est pas valide en l'état, vous avez un délai de 15 jours pour apporter les modifications nécessaires à votre demande.</p>
     <p v-else-if="status=='refund'" >Votre remboursement a été effectué. Nous espérons que vous êtes satisfait du traitement de votre demande.</p>
     <p v-else-if="status=='pending'">Votre participation a bien été enregistrée. Nous la traiterons dans les meilleurs délais.</p>
     <p v-else-if="status=='invalid'">Nous n'avons pas été en mesure de valider votre participation conformément au règlement de l'opération. Celle-ci est définitivement clôturée.</p>

      <div class="bloc-infos">
        <ul>
          <li>ID  : {{submission.operationId}}</li>
          <li>Produit acheté :  {{ submission.produit_achete }}</li>
          <li>Enseigne : {{ submission.husk_dealer }}</li>
          <li v-if="code">
          <i class="fas fa-barcode ico2"></i> &nbsp; Facture d'installation: <a v-bind:href="submission.bar_code" target="_blank">{{ code }}</a>
            &nbsp;
            <span v-if="barcode_validation==true"><i class="far fa-check-circle ico3 blue"></i>&nbsp;</span>
            <span v-if="barcode_validation==false"><i class="far fa-times-circle ico3 orange"></i>&nbsp; </span>
            <span  v-if="status=='missing' && barcode_validation==false">
              <input id="upload_code" @change="filecode" ref="myFile1" type="file"/>
              <label for="upload_code">Refresh</label>
            </span>
            <div v-if="fileName1" class="newfile"><i class="far fa-file-alt"></i> {{ fileName1 }}</div>
          </li>
          <li v-if="receipt">
            <i class="fas fa-file-invoice ico2"></i> &nbsp; Preuve d'achat : <a  v-bind:href="submission.receipt" target="_blank">{{ receipt }}</a>&nbsp;
            <span v-if="receipt_validation==true"><i class="far fa-check-circle ico3 blue"></i>&nbsp;</span>
            <span v-if="receipt_validation==false"><i class="far fa-times-circle ico3 orange"></i>&nbsp;</span>
            <span v-if="status=='missing' && receipt_validation==false">
              <input id="upload_receipt" @change="filereceipt" ref="myFile2" type="file"/>
              <label for="upload_receipt">Refresh</label>
            <div v-if="fileName2" class="newfile"><i class="far fa-file-alt"></i> &nbsp;{{ fileName2 }}</div>
            </span>
          </li>
        </ul>
      </div>
      <div v-if="fileName1 || fileName2" class="send">
          <br />
          <button @click.prevent="sendFile()">Rafraîchir</button>
       </div>
 </div>

 </form>
     
</main>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';

export default {
  name: 'Status',
  data: function() {
    return {
      title : "",
      fileName1: "",
      fileName2: "",
      status : 'En attente',
      receipt_validation : false,
      barcode_validation : false,
      validation_comment : '',
      motif_nc : '',
      code :undefined,
      receipt : undefined,
      submission: {},
    };
  },
  methods : {
    getData(op_id) {
     return axios.get(
      "https://api.lucien.ai/api/formdata/"+op_id
    )},
    sendFile() {

                    this.$swal({
                        position: 'top-end',
                        title: 'Téléchargement en cours!',
                        html: 'Merci pour votre patience',
                        timer: 999999000,
                        onBeforeOpen: () => {
                            this.$swal.showLoading(0)
                        },
                        onClose: () => {
                            clearInterval(0)
                        }
                    })

      if(this.fileName1 && this.fileName2) {

          const reader = new FileReader();
          reader.readAsDataURL(this.$refs.myFile1.files[0])
          reader.onloadend = async () => {
            let baseString = reader.result;
            let result = await axios.post('https://api.lucien.ai/api/update_document/'+this.$route.params._id, {
                imgcode: baseString,
            });

          const reader2 = new FileReader();
          reader2.readAsDataURL(this.$refs.myFile2.files[0])
          reader2.onloadend = async () => {
            let baseString2 = reader2.result;
            let result = await axios.post('https://api.lucien.ai/api/update_document/'+this.$route.params._id, {
                imgcreceipt: baseString2,
            });
          }
              this.$swal({
                type: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#C94330',
                text: 'Vos documents ont été mis à jour!'
                }).then(function() {
                  location.reload();
                })
          }

      } else if (this.fileName1 && !this.fileName2) {

          const reader = new FileReader();
          reader.readAsDataURL(this.$refs.myFile1.files[0])
          reader.onloadend = async () => {
            let baseString = reader.result;
            let result = await axios.post('https://api.lucien.ai/api/update_document/'+this.$route.params._id, {
                imgcode: baseString,
            });

            this.$swal({
              type: 'success',
              confirmButtonText: 'OK',
              confirmButtonColor: '#C94330',
              text: 'Vos documents ont été mis à jour!'
              }).then(function() {
                location.reload();
              })
          }

      } else if (!this.fileName1 && this.fileName2) {

          const reader2 = new FileReader();
          reader2.readAsDataURL(this.$refs.myFile2.files[0])
          reader2.onloadend = async () => {
            let baseString = reader2.result;
            let result = await axios.post('https://api.lucien.ai/api/update_document/'+this.$route.params._id, {
                imgcreceipt: baseString,
            });

            this.$swal({
              type: 'success',
              confirmButtonText: 'OK',
              confirmButtonColor: '#C94330',
              text: 'Your documents have been updated!'
              }).then(function() {
                location.reload();
              })
          }
      }

    },
    filecode() {
      var fcode = this.$refs.myFile1.files
      if (fcode.length > 0) {
          this.fileName1 = fcode[0].name;
      }
    },
    filereceipt(event) {
      var freceipt = this.$refs.myFile2.files
      if (freceipt.length > 0) {
          this.fileName2 = freceipt[0].name;
      }
    }
  },
  async mounted() {

    try {
      let data = await axios.get(
        "https://api.lucien.ai/api/data/submission/" + this.$route.params._id
      );

    var gameData = await this.getData(data.data.operationId)
    this.title = gameData.data.title;

    Vue.set(this, 'submission', data.data);



    if(this.submission.participationValide=='VALID') { this.status = 'valid' }
    else if(this.submission.participationValide=='INVALID') { this.status = 'invalid' }
    else if(this.submission.participationValide=='PENDING' || this.submission.participationValide == 'TO_CHECK_AGAIN') { this.status = 'pending' }
    else if(this.submission.participationValide=='REFUND_DONE') { this.status = 'refund' }
    else if(this.submission.participationValide=='MISSING') { this.status = 'missing' }

    if(this.submission.receipt_validation == true) { this.receipt_validation = this.submission.receipt_validation; }
    if(this.submission.barcode_validation == true) {  this.barcode_validation = this.submission.barcode_validation; }
    this.validation_comment = this.submission.validation_comment;
    this.motif_nc = this.submission.motif_nc;

    if(this.submission.bar_code && this.submission.bar_code != 'Error uploading data') {
      this.code = this.submission.bar_code
    } else {
      this.code = 'N/A';
    }

    if(this.submission.receipt && this.submission.receipt != 'Error uploading data') {
      this.receipt = this.submission.receipt
    } else {
      this.receipt = 'N/A';
    }

    } catch(error) {
      console.log(error)
          this.$router.push("/error")
    }
  }
}

</script>
<style>
a {
  color:#EB4F15;
}

.strong {
  font-weight: bold;
}

.cod-data {
  font-weight: bold;
  
}
</style>